import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { FiCast , FiLayers , FiUsers , FiMonitor  } from "react-icons/fi";
import ContactThree from "./contact/ContactThree";

const ServiceList = [
    {
        
        title: 'With the innovative and technical advancement in the 21st-century, everyone wants to encounter the finest technology  without wasting a lot of their energy and exhausting their bustling minds.  The same goes for browsing the web - where the fast and proficient the site , the effective results it obtains.',
        description: "We assist you with creating products that are not just profoundly customized to clients' necessities and are simple to use, but also to deliver noticeable results  to your business. Our team of designers is committed to making designs your customers will enjoy engaging with. Our design solutions are always out-of-the-box and tailor-made to suit your specific business needs.",
        paraone: 'UI design deals with colour, Illustration ,Photography, Typography ,icons, visual design and graphics. User Interface is how the website page is physically laid out.It is responsible for the creative and visual pieces of the site. We will talk to you about where things should go and what colours to use and make it more attractive to clients.',
        paratwo: 'Whereas UX designs include usability testing,Psychology,Interaction design, content strategy, wireframes, prototypes, Information architecture, understanding the problem,  design and user research. User experience focuses on the interactive side of the website, how it behaves, such as box sliding out and how people might interact with it, such as where they will click first. It handles the architecture of the content and the site maps.',
        parathree: 'Building an effective item goes a long way than just making it beautiful. Accomplishing this requires a profound comprehension of your clients and their necessities, intelligent design, and thorough testing . Our designs improve the user experience and customer satisfaction that ultimately helps increase the number of users.',

    },
    
]

const ServiceListOne = [
    {
        icon: <FiMonitor />,
        name: 'web',
        title: 'Web Development',
        description: 'We will assist you to create incentives from technology faster,  with a web development process that truly serves your business.'
    },
    {
        icon: <FiLayers />,
        name: 'design',
        title: 'UI/UX Design',
        description: 'We provide smart designs with refreshing ideas that can captivate the users mind with the best impression of your product.'
    },
    {
        icon: <FiCast />,
        name: 'devops',
        title: 'DevOps Services',
        description: 'With Devops services-  gain the ability to better respond to customer needs, increase confidence in the applications  and achieve business goals faster.'
    },
    {
        icon: <FiUsers />,
        name: 'support',
        title: 'Dedicated Support',
        description: 'We focus on providing better technical advice and support to your customers, that will help them use your product.'
    },
]


class Design extends Component{
    render(){
        
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='UI/UX Design' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'UI/UX Design'} description={'Fresh ideas. Thoughtful design. Measurable results'}  />
                {/* End Breadcrump Area */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h3 className="tilte">{val.title}</h3>
                                    <p>{val.description}</p>
                                    <p>{val.paraone}</p>
                                    
                                    <p>{val.paratwo}</p>
                                    <p>{val.parathree}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* End Columns Area  */}

               

                 {/* Start Contact Form  */}
                 <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/contact.jpg" />
                </div>
                {/* Start Contact Form  */}

                {/* Start Service Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">SERVICES</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                        
                        
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.name}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                           
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area }
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Design;