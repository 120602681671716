import React, { Component } from "react";
import axios from "axios";

class ContactThree extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            phone: '',
            company: '',
            message: '',
            status: "Submit",
            sent: false
        };
    }
    handleChange(event) {
        const field = event.target.id;
        if (field === "name") {
            this.setState({ name: event.target.value });
        } else if (field === "email") {
            this.setState({ email: event.target.value });
        } else if (field === "phone") {
            this.setState({ phone: event.target.value });
        } else if (field === "company") {
            this.setState({ company: event.target.value });
        } else if (field === "message") {
            this.setState({ message: event.target.value });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ status: "Sending" });
        axios({
            method: "POST",
            url: "/contact",
            data: this.state,
        }).then((response) => {
            if (response.data.status === "sent") {
                // alert("Message Sent");
                this.setState({ sent: true })
                this.setState({ name: "", email: "", phone: "", company: "", message: "", status: "Submit" });
            } else if (response.data.status === "failed") {
                alert("Message Failed");
            }
        });
    }
    render() {
        let buttonText = this.state.status;

        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <div className="description">
                                    <p>We would be happy to hear from you, lets discuss your ideas</p>
                                </div>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                                    <label htmlFor="name">Name:</label>
                                    <input
                                        type="text"

                                        id="name"
                                        value={this.state.name}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        placeholder="Your Name *"
                                    />


                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        id="email"
                                        value={this.state.email}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        placeholder="Your Email *"
                                    />


                                    <label htmlFor="phone">Phone:</label>
                                    <input
                                        type="tel"
                                        pattern="[0-9]{10}"
                                        id="phone"
                                        value={this.state.phone}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        placeholder="Your Phone *"
                                    />


                                    <label htmlFor="company">Company:</label>
                                    <input
                                        type="text"

                                        id="company"
                                        value={this.state.company}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        placeholder="Company name*"
                                    />

                                    <label htmlFor="message">Message:</label>
                                    <textarea


                                        id="message"
                                        value={this.state.message}
                                        onChange={this.handleChange.bind(this)}
                                        required
                                        placeholder="Your Message *"
                                    />

                                    {!this.state.sent &&
                                        <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{buttonText}</button>
                                    }

                                    {this.state.sent &&

                                        <button disabled style={{
                                            cursor: "not-allowed", 
                                            backgroundColor: "green", 
                                            border: "2px solid green",
                                            fontSize: "16px",
                                            textTransform: "uppercase",
                                            letterSpacing: "2px",
                                            padding: "15px 40px",
                                            borderRadius: "6px",
                                            display: "inline-block",
                                            fontWeight: "500",
                                            transition: "0.3s",
                                            color:"white"
                                        }}>SENT</button>

                                    }
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="contact img" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;