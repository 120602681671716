import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
//import CounterOne from "../elements/counters/CounterOne";
//import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
//import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
//import BlogContent from "../elements/blog/BlogContent";
//import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component{
    render(){
        //const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="Custom Software Development company" />

                <Header headerPosition="header--transparent logoresize" logo="all-dark" color="color-black" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}
                
                {/* Start About Area */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area */}

                <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>

                {/* Start Exp Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--3">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">Our Approach</h2>
                                    <p>We have established a process that keeps you informed every step of the way and us delivering something unique.</p>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                {/* End Exp Area */}

                {/* Start Exp Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">Why choose Wirelab ?</h2>
                                    
                                </div>
                                {/* Start Columns Area  */}
                                <div className="rn-columns-area ptb--120 bg_color--1">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="single-column">
                                                            <p>We assemble applications that work for your business and your clients. Regardless of whether it's an intricate platform and backend data set, a responsive web application, or a marketing website, our web solutions function work easily and convey a steady encounter to clients across all stages.We also incorporate the use of  visual contents as it provides the users with a clear picture of what the product looks like. Not all customers understand the services or products offered through text. So the inclusion of images makes it simple to drive the message home.</p>
                                                            <p>We are strategically creative,flexible and adaptable - Wirelab has an eclectic mix of designers, developers, marketers, project managers and top management who work on your project to impart real value to your business.Every website is custom developed to promote your business. For us, the business governs the use of technology and not the other way round.</p>
                                                            <p>We have considerable expertise in web application development using development languages like PHP, Python, JAVA, databases like MySQL, PostGreSQL, MongoDB, Front-end frameworks like Angular, React, Vue.</p>

                                                            
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* Start Columns Area  */}
                            </div>
                        </div>
                    </div>    
                </div>
                {/* End Exp Area */}


                {/* Start Portfolio Area }
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start CounterUp Area }
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area }
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Blog Area }
                <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">View All News</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}


                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </Fragment>
        )
    }
}
export default MainDemo;