import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
//import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp  } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";



class Privacy extends Component{
    render(){
        
        

        return(
            <React.Fragment>
                <PageHelmet pageTitle='Privacy Policy' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--200 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h2 className="title">Privacy Policy</h2>
                                    
                                    <p>Wirelab recognizes the privacy interests of the businesses on which it collects information and has implemented comprehensive and formal data protection policies which balance privacy expectations with the legitimate business need of our customers.</p>
                                    <h3 className="title">DATA COMPILATION</h3>
                                    <p>The information compiled by Wirelab incorporates several elements of business and contact information. The information is acquired from reliable sources and Wirelab takes great care through specific quality control to verify and maintain the accuracy of the information.</p>
                                    
                                    <h3 className="title">SECURITY</h3>                            
                                    <p>Wirelab uses several security systems and procedures to safeguard the information, ensuring the data is accessed only by authorized individuals for legitimate business needs. Wirelab will not transmit or sell the compiled information to third parties without obtaining a written or electronic agreement that defines the rights and responsibilities with respect to the data. Further Wirelab Consulting Pvt. will review our customers use of the data to ensure sensitive data is being handled in accordance with required protections and that this infomration is not used for deceptive, misleading or suspicious purposes.</p>
                                    <p>Wirelab is responsible for the security of personal information provided by visitors. Strict measures will be taken to assure against un authorized access, or dissemination of personal information.</p>    
                                    
                                    
                                    <h3 className="title">COMPLIANCE</h3>
                                    <p>Wirelab complies with all applicable federal, state and international laws concerning data privacy, as well as best practices and procedures and guidelines established by industry groups of which the company is a contributing member, including the Direct Marketing Association (DMA). Wirelab maintains agreements with its customers containingstrict controls on the data as required by the applicable laws and industry standards and guidelines established by those groups.</p>
                                    
                                    <h3 className="title">COLLECTION OF INFORMATION</h3>
                                    <p>Wirelab does not collect personally identifiable information about the visitors except when visitors specifically provide such information on a voluntary basis.</p>
                                    <p>Wirelab receive and collect personally identifiable information – such as name, telephone numbers, e-mail addresses, and/or postal addresses – from you via our website when you submit inquiries or requests to us, become a registered user of our websites, sign up to receive newsletters or bulletins from us, participate in our research activities, or choose to provide information to us for some other reason. Any personally identifiable information you provide will be used by us or our service providers in connection with providing services to you and in order to process any requests or inquiries that you initiate. While you can choose not to provide your personal information, that decision may limit our ability to contact you and our ability to provide services or products to you. Also, please note that if you have provided us with a postal address, an e-mail address, or a telephone number, a Wirelab representative may contact you unless you have requested that we put you on one of our do-not-contact lists.</p>
                                    <p>When you visit our website, we may collect information concerning your use of the website, including Internet domain, IP address and other information concerning your web browser. We also may assemble this information into aggregate form (i.e., combine non-personal information obtained from many different users) to better understand how our website is being used. We may also use this aggregate information to analyze our website's utility, enhance the areas and functionality within our site that appear to be of interest to various users and better communicate our products and services.</p>
                                    <p>Also, we may use "cookies" or similar tools, which are typically small pieces of information that are delivered by our web servers and stored by your Internet browser on your computer's hard drive. The information stored and transmitted by these tools may be used to facilitate the functionality of our website, to learn about your preferences and internet session information, to provide a more relevant online experience, to study the efficacy of marketing campaigns, and for other legitimate website operation and marketing purposes.</p>

                                    <h3 className="title">OPT OUT POLICY</h3>
                                    <p>Upon a visitors request, Wirelab will allow any visitor to "Opt Out" for further promotional contacts, use reasonable efforts to allow visitors to update/correct personal information previously submitted which he/she states is erroneous to the extent such activities will not compromise privacy or security interests and initiate reasonable efforts to functionally delete the visitor and his/her personal information from its database.</p>
                                    
                                    <h3 className="title">DISCLOSURE OF INFORMATION</h3>
                                    <p>Wirelab may disclose visitor information if requested or required to by law, court order, or government or law enforcement authority. In addition, Wirelab may in its sole discretion disclose visitor information if it believes in good faith that disclosure is otherwise necessary or advisable for any reason including without rights or properties of Wirelab or any third party.</p>
                                    
                                    <h3 className="title">CHANGES TO THIS POLICY</h3>
                                    <p>Wirelab reserves the right to amend this privacy policy at any time, without prior notice.</p>
                                    <p>If users have any questions or suggestions regarding our privacy policy, please email us at <b>privacy@wire-lab.com</b></p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Privacy;