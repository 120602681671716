import React, { Component } from "react";
import { VscCircleOutline } from 'react-icons/vsc';

class About extends Component{
    render(){
        let title = 'Bring your ideas to life with Wirelab.';
        
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/home.jpg" alt="Home Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                
                                                <p><VscCircleOutline /> An app your users will love - beautiful, functional and helpful.Respond to the various and dynamic market needs and stay ahead of the competition.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                
                                                <p><VscCircleOutline /> Adopt flexible solutions to add new features that cater to the changing needs of your users and market.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                
                                                <p><VscCircleOutline /> Choose the right technology solutions and  build better products faster for greater customer satisfaction.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;