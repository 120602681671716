// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
//import Demo from './page-demo/Demo';

//import CorporateBusiness from './home/CorporateBusiness';
import MainDemo from './home/MainDemo';
// Dark Home Layout 
//import DarkMainDemo from './dark/MainDemo';
//import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Web from "./elements/Web";
import Design from "./elements/Design";
import DevOps from "./elements/DevOps";
//import Contact from "./elements/Contact";
import Support from "./elements/Support";
import Approach from "./elements/Approach";
import Privacy from "./elements/Privacy";
import error404 from "./elements/error404";


// Blocks Layout


import ContactForm from "./blocks/ContactForm";


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo}/>
                    
                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/web`} component={Web}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/design`} component={Design}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={ContactForm}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/devops`} component={DevOps}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/support`} component={Support}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/approach`} component={Approach}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy}/>


                    {/* Blocks Elements  */}
                    

                    
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();