import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import ContactThree from "./contact/ContactThree";

const ServiceList = [
    {
        
        title: 'For us, Collaboration comes first. Getting to know you, your values and your goals and your product vision is the most important thing. We go ahead with Research driven design, we don’t just assume but wait for confirmation and then move ahead.',
        description: 'We also believe that Communication is key and therefore during the development of every project, from the moment the scope of the project is defined to updates during development, we make sure to keep you informed every step of the way.',
        paraone: 'Our approach comes purely from experience, things we have done really well in the past - others that could have been done a little better. It is how we improve and it is why we have a linear process for our approach, because we know what works and what does not.To provide stylish and interaction design we focus on a custom approach.',
        paratwo: 'No matter how complex the technological aspect of the product, we make sure that it will be easy and intuitive to navigate. We are capable of producing clickable prototypes to provide a comprehensive vision of the final result. Our devoted team takes great pride in what we do and make sure to deliver something that we can all be very proud of, that makes a difference for our clients and their customers which is what drives us to give our best.',
        parathree: 'Our strategy ensures that the information architecture  and user experience of your project are absolutely top notch and of the best quality.To be confident that every individual part complements the unified system and results in consistent, reliable product functionality, exhaustive testing is performed throughout the entire development process. With the product designed, developed, and tested, it is then ready for launch.',
    },
    
]

class Approach extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Our Approach' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area }
                <Breadcrumb title={'Our Approach'} description={'Our process is built for progress'}  />
                {/* End Breadcrump Area */}
                {/* Start Breadcrump Area */}
                <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--34" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--200">
                                    <h2 className="title">Our Approach</h2>
                                    <p>Our process is built for progress</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                {/* Start top Area  }
                <div className="rn-about-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        
                                        <h2 className="title">Our process is built for progress.</h2>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/pexels-5.jpg" alt="About Images"/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End top Area  */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="single-column">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/our_approach2.jpg" alt="About Img"/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* Start Columns Area  */}
                
                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    
                                    <p>{val.paraone}</p>  
                                    <p>{val.paratwo}</p>
                                    <p>{val.parathree}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* End Columns Area  */}
                {/* Start top Area  }
                <div className="rn-about-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="title">Human-Centered Design</h3>
                                        <h4 className="title">Focusing On People’s Real Needs</h4>
                                        <p>For every screen we design and build, the person interacting with that screen is top of mind. What are their needs? More importantly, what are the behaviors motivating those needs? By taking a human-centered design approach, we’re able to better meet and anticipate people’s expectations as they interact with your business and products.</p>

                                        <p>To achieve this, we conduct thorough foundational research before we even begin to think about the design of the product. This allows us to really understand the people you’re trying to reach and how to successfully engage them with your product or service. From there, we use our findings to guide how we design in a way that solves the problem and meets people’s needs in the simplest way possible.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/Design-Desktop.png" alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End top Area  */}

                

                 {/* Start Contact Form  */}
                 <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/contact.jpg" />
                </div>
                {/* Start Contact Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Approach;