import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { FiCast , FiLayers , FiUsers  , FiCheck , FiMonitor } from "react-icons/fi";
import ContactThree from "./contact/ContactThree";

const ServiceList = [
    {
        
        title: 'With DevOps Services, seek to innovate rapidly without sacrificing quality, stability and productivity.  To do that, we use highly productive tools, automate mundane and manual steps and iterate in small increments through automated testing and continuous integration.',
        description: 'Our teams ideate, define, and describe features and capabilities of the applications and systems that are being built. We also include all aspects of coding—writing, testing, reviewing and the integration of code that can be arranged into various environments.We also deploy applications into production environments in a consistent and reliable way.',
        paraone: 'DevOps includes 4 phases- plan, develop, deliver and operate. Each phase relies on the others and the phases are not role-specific. Each role is involved in each phase to some extent.',
        paratwo: 'Our teams work to ensure system reliability, high availability and aim for zero downtime while reinforcing security and governance. We seek to identify issues before they affect the customer experience and mitigate issues quickly when they do occur. Maintaining this vigilance requires rich telemetry, actionable alerting and full visibility into applications and the underlying system.',
    },
    
]

const ServiceListOne = [
    {
        icon: <FiMonitor />,
        name: 'web',
        title: 'Web Development',
        description: 'We will assist you to create incentives from technology faster,  with a web development process that truly serves your business.'
    },
    {
        icon: <FiLayers />,
        name: 'design',
        title: 'UI/UX Design',
        description: 'We provide smart designs with refreshing ideas that can captivate the users mind with the best impression of your product.'
    },
    {
        icon: <FiCast />,
        name: 'devops',
        title: 'DevOps Services',
        description: 'With Devops services-  gain the ability to better respond to customer needs, increase confidence in the applications  and achieve business goals faster.'
    },
    {
        icon: <FiUsers />,
        name: 'support',
        title: 'Dedicated Support',
        description: 'We focus on providing better technical advice and support to your customers, that will help them use your product.'
    },
]


class DevOps extends Component{
    render(){
        var namesItemOne = [
            'Accelerating time to market',
            'Adapting to the market and competition',
            'Maintaining system stability and reliability',
            'Improving the mean time to recovery',
            
        ];
        
        
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='DevOps Services' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'DevOps Services'} description={'Make your software success-ready'}  />
                {/* End Breadcrump Area */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h3 className="tilte">{val.title}</h3>
                                    <p>{val.description}</p>
                                    <p>{val.paraone}</p>
                                    
                                    <p>{val.paratwo}</p>
                                    <p>{val.parathree}</p>
                                    <div className="mt--30">
                                    <h4 className="title">With DevOps services have a tremendously positive effect on the overall speed, scalability, security of any software solution and achieve business goals like:</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* End Columns Area  */}

               

                 {/* Start Contact Form  */}
                 <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/contact.jpg" />
                </div>
                {/* Start Contact Form  */}

                {/* Start Service Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">SERVICES</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                        
                        
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.name}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                           
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area }
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default DevOps;