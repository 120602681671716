import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { FiCast , FiLayers , FiUsers  , FiCheck , FiMonitor } from "react-icons/fi";
import ContactThree from "./contact/ContactThree";

const ServiceList = [
    {
        
        title: 'Technology evolves every day bringing in new possibilities for businesses.Even with technology making things easier, it becomes hard for businesses to maximize on opportunities if they don’t have the expertise to help them tap into different technology changes.',
        description: 'When it comes to having a successful online platform, the user must enjoy easy navigation. Essentially, information provided on the website should be easy to access. ',
    },
    
]

const ServiceListOne = [
    {
        icon: <FiMonitor />,
        name: 'web',
        title: 'Web Development',
        description: 'We will assist you to create incentives from technology faster,  with a web development process that truly serves your business.'
    },
    {
        icon: <FiLayers />,
        name: 'design',
        title: 'UI/UX Design',
        description: 'We provide smart designs with refreshing ideas that can captivate the users mind with the best impression of your product.'
    },
    {
        icon: <FiCast />,
        name: 'devops',
        title: 'DevOps Services',
        description: 'With Devops services-  gain the ability to better respond to customer needs, increase confidence in the applications  and achieve business goals faster.'
    },
    {
        icon: <FiUsers />,
        name: 'support',
        title: 'Dedicated Support',
        description: 'We focus on providing better technical advice and support to your customers, that will help them use your product.'
    },
]


class Web extends Component{
    render(){
        var namesItemOne = [
            'Planning and analysing',
            'Web page design & finalisation',
            'Functionalities & features',
            'Testing and fixing bugs',
            'Final delivery',
            
        ];
        
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Web Development' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Web Development'} description={'Build an engaging, reliable and safe Web application'}  />
                {/* End Breadcrump Area */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h3 className="tilte">{val.title}</h3>
                                    <p>{val.description}</p>
                                    <p>We assemble applications that work for your business and your clients. Regardless of whether it's an intricate platform and backend data set, a responsive web application, or a marketing website, our web solutions function work easily and convey a steady encounter to clients across all stages.We also incorporate the use of  visual contents as it provides the users with a clear picture of what the product looks like. Not all customers understand the services or products offered through text. So the inclusion of images makes it simple to drive the message home.</p>
                                    <div className="mt--30">
                                    <h4 className="title">Web development Includes 5 steps</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <p>Your website is an important part of your online marketing presence, and we help you build a better website for your business.We believe that with a good website, the business can show that it is dedicated to offering the best services and information to all their clients.our team masterfully combines proven development techniques and innovations to provide an exceptional user experience.</p>
                                    <p>With profound involvement with web and back-end platforms, our product arrangements are adaptable, accessible  and above all, Secure. From your initial concept to your final product, we will guide you through the process of building a quality application lined up with your business goals.We can help your business expand Reach,Increase sales, maintain consistency and save on costs with easier navigation.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* End Columns Area  */}

               {/* Start Columns Area  }
               <div className="rn-columns-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="title">We assemble applications that work for your business and your clients. Regardless of whether it's an intricate platform and backend data set, a responsive web application, or a marketing website, our web solutions function work easily and convey a steady encounter to clients across all stages.We also incorporate the use of  visual contents as it provides the users with a clear picture of what the product looks like. Not all customers understand the services or products offered through text. So the inclusion of images makes it simple to drive the message home.</h4>
                                    
                                    
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                <div className="single-column">

                                <div className="mt--30">
                                <h4 className="title">Web development Includes 5 steps</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}

                

                 {/* Start Contact Form  */}
                 <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/contact.jpg" />
                </div>
                {/* Start Contact Form  */}

                {/* Start Service Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">SERVICES</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                        
                        
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.name}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                           
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area }
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Web;