import React, { Component } from "react";
//import ServiceOne from "../../elements/service/ServiceOne";

class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--3" >
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">We turn good ideas into great products.</h1>
                                <p>A digital experience should always meet clients expectations and to meet those expectations, we utilize multiple platforms and approaches to help our customers build enterprise website hosting experience.We create unique products considering their purpose and philosophy.</p>

                            </div>
                        </div>
                    </div>
                    {/* Start Service Area }
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;