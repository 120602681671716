import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        name: 'web',
        title: 'Web Development',
        description: 'We will assist you to create incentives from technology faster,  with a web development process that truly serves your business.'
    },
    {
        icon: <FiLayers />,
        name: 'design',
        title: 'UI/UX Design',
        description: 'We provide smart designs with refreshing ideas that can captivate the users mind with the best impression of your product.'
    },
    {
        icon: <FiCast />,
        name: 'devops',
        title: 'DevOps Services',
        description: 'With Devops services-  gain the ability to better respond to customer needs, increase confidence in the applications  and achieve business goals faster.'
    },
    {
        icon: <FiUsers />,
        name: 'support',
        title: 'Dedicated Support',
        description: 'We focus on providing better technical advice and support to your customers, that will help them use your product.'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services';
        
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.name}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
