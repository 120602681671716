import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import ContactThree from "./contact/ContactThree";

const ServiceList = [
    {
        
        title: 'Software is never truly finished. No matter how solid the released product is, changes are inevitable. So are patch releases, language and platform updates, and new enhancements.Technical or ‘tech’ support is a form of customer communication that product-centric companies use to help their users get the most out of their products.',
        description: 'Our passion is rooted in your success. We engage deeply with you to understand your business and its processes and ensure that the solution we build fits your needs now and into the future.Our tech support team keeps your product secure, maintained, and enhanced well after it launches.',
        paraone: 'Tech support is crucial to your overall success, if you run a product based business as you may risk losing customers due to problems like can’t log into the account or a feature or module which used to work normally isn’t working or a “user” error, a bug or technical issue which can frustrate the customer and hence stop using your product.',
        paratwo: 'With technical support, you can get advice on whatever you may be working on. Discussing your task or issue with our support technician may provide you a solution or alternate approach. Collaborating with technical support can be very valuable and save you time. We have experts on the software who can help with documentation, informal knowledge transfer, and training while resolving issues, implementing changes, or giving advice.',
        parathree: 'Our team of Tech support helps you through live chat, email or phone – and aims to solve technical problems such as installation issues, login errors and other technical difficulties that can have a negative impact on the user experience. In essence, our tech support focuses on helping customers to use a product more effectively.',
        parafour: 'One of the best values we provide by a technical support service is the peace of mind. Should all hell break loose, you don’t need to worry about a thing. We are here to bail you out of any major issues that may go down. You can reduce stress and time worrying just knowing technical support is available.',
    },
    
]

const ServiceListOne = [
    {
        icon: <FiMonitor />,
        name: 'web',
        title: 'Web Development',
        description: 'We will assist you to create incentives from technology faster,  with a web development process that truly serves your business.'
    },
    {
        icon: <FiLayers />,
        name: 'design',
        title: 'UI/UX Design',
        description: 'We provide smart designs with refreshing ideas that can captivate the users mind with the best impression of your product.'
    },
    {
        icon: <FiCast />,
        name: 'devops',
        title: 'DevOps Services',
        description: 'With Devops services-  gain the ability to better respond to customer needs, increase confidence in the applications  and achieve business goals faster.'
    },
    {
        icon: <FiUsers />,
        name: 'support',
        title: 'Dedicated Support',
        description: 'We focus on providing better technical advice and support to your customers, that will help them use your product.'
    },
]


class Support extends Component{
    render(){
       
        
        
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Dedicated Support' />
                <Header headerPosition="header--transparent logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Dedicated Support'} description={'Keep your app stable and in action 24/7'}  />
                {/* End Breadcrump Area */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                {ServiceList.map( (val , i) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h3 className="tilte">{val.title}</h3>
                                    <p>{val.description}</p>
                                    <p>{val.paraone}</p>
                                    
                                    <p>{val.paratwo}</p>
                                    <p>{val.parathree}</p>
                                    <p>{val.parafour}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                {/* End Columns Area  */}

               

                

                 {/* Start Contact Form  */}
                 <div className="rn-contact-form-area ptb--120 bg_color--1">
                    <ContactThree contactTitle="Contact Us" contactImages="/assets/images/about/contact.jpg" />
                </div>
                {/* Start Contact Form  */}

                {/* Start Service Area */}
                <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center">
                                    
                                    <h2 className="title">SERVICES</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                        
                        
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href={val.name}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                           
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area }
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Support;